import React, { useEffect, useState, useCallback } from "react";
import { fieldmap, stepSop } from "../../fieldmap";
import InputField from "../../fieldComponants/InputField";
import { ScanField } from "../../fieldComponants/ScanField";
import FileField from "../../fieldComponants/FileField";
import StatusField from "../../fieldComponants/StatusField";
import { NavStep } from "../../fieldComponants/NavStep";
import { Field, Step } from "../../types";
import SelectField from "../../fieldComponants/SelectField";
import { useAppDispatch, useAppSelector } from "../../../../store/rtctype";
import { ValidationModel } from "../../../../components/Model/ValidationModel";
import FileReadField from "../../fieldComponants/FileReadField";
import SopHeader from "../sopHeader";
import { getLotService, getOEMModelData } from "../../../../service/apiService";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import SerialNumberGeneration from "../../fieldComponants/CustomAction/SerialNumberGeneration";
import { handleCreateModule } from "../../serviceCallback";
import {
  handleFieldData,
  resetStepData,
  resetToggle,
} from "../../../../reducers/inspectionReducer";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

type Worksheet = XLSX.WorkSheet;
type Range = XLSX.Range;
export default function DailyPrepForm({ step }: { step: string }) {
  const formData = useAppSelector((state: any) => state.inspection).formData;
  const userData = useAppSelector((state) => state.user).user;
  const [currentActive, setCurrentActive] = useState<number>(0);
  const [stepFieldMap, setStepFieldMap] = useState<Step>(fieldmap[step]);
  const [eropen, setErOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [paramError, setParamError] = useState<string[]>([]);
  const [moduleData, setModuleData] = useState<any>([]);

  const [isTable, setIsTable] = React.useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [oemData, setOEMData] = useState<any>();
  const getOEM = () => {
    const authToken = localStorage.getItem("accessToken") as string;

    getOEMModelData(authToken).then((res) => {
      if (res.success && stepFieldMap) {
        setOEMData(res.data);
        const { oem_models, oems } = res.data;

        const new_feilds = { ...stepFieldMap };

        const oem_option = stepFieldMap["fields"].findIndex(
          (field: Field) => field.key === "oem"
        );
        const oem_model_option = stepFieldMap["fields"].findIndex(
          (field: Field) => field.key === "oem_model"
        );

        new_feilds.fields[oem_option]["option"] = oems;
        new_feilds.fields[oem_model_option]["option"] = formData[step]["oem"]
          ? oem_models?.filter((obj: any) => obj?.oem === formData[step]["oem"])
          : oem_models;
        setStepFieldMap({ ...new_feilds });
      }
    });
  };

  useEffect(() => {
    if (step === "step_4") {
      const authToken = localStorage.getItem("accessToken") as string;
      getLotService(authToken).then((res: any) => {
        if (res.success && step === "step_4" && fieldmap) {
          if (res?.data?.status && res?.data?.data?.length !== 0) {
            const arr: any = [];
            res?.data?.data?.map((obj: any) =>
              arr.push({ identifier: obj?._id, name: obj?.identifier })
            );
            fieldmap["step_4"].fields[0]["option"] = arr;
            dispatch(
              handleFieldData({
                step: "step_4",
                value: "",
                key: fieldmap["step_4"].fields[0].key,
              })
            );
          }
        }
      });
    }
  }, [step]);

  useEffect(() => {
    const currentFormData = { ...formData[step] };
    const updatedFields: any = stepFieldMap;
    if (currentFormData?.oem&&oemData) {
      const { oem_models } = oemData;

      const oem_model_option = stepFieldMap["fields"].findIndex(
        (field: Field) => field.key === "oem_model"
      );
      updatedFields.fields[oem_model_option].option = oem_models?.filter(
        (obj: any) => obj?.oem === formData[step]["oem"]
      );
      if (
        oem_models?.filter((obj: any) => obj?.oem === formData[step]["oem"])
          ?.length > 0
      ) {
        dispatch(
          handleFieldData({
            step: step,
            value: oem_models?.filter(
              (obj: any) => obj?.oem === formData[step]["oem"]
            )[0]?.code,
            key: "oem_model",
          })
        );
      }
      setStepFieldMap({ ...updatedFields });
    }
  }, [formData[step]?.oem]);

  useEffect(() => {
    getOEM();
  }, []);

  useEffect(() => {
    const authToken = localStorage.getItem("accessToken") as string;
    // getOEMModelData(authToken).then((res) => {
    // if (res.success) {
    if (oemData) {
      const { oem_models, oems } = oemData;
      if (oem_models) {
        const new_feilds = { ...stepFieldMap };
        const oem_option = stepFieldMap["fields"].findIndex(
          (field: Field) => field.key === "oem"
        );
        const oem_model_option = stepFieldMap["fields"].findIndex(
          (field: Field) => field.key === "oem_model"
        );

        new_feilds.fields[oem_option]["option"] = oems;
        new_feilds.fields[oem_model_option]["option"] = formData[step]["oem"]
          ? oem_models?.filter((obj: any) => obj?.oem === formData[step]["oem"])
          : oem_models;
        setStepFieldMap(new_feilds);
        // }
      }
    }
    // })
  }, [formData, formData[step]?.oem]);
  console.log("%c Line:132 🥕 formData[step]", "color:#7f2b82", formData[step]);
  // console.log('formData[step]["oem"]: ', formData[step]["oem"]);
  //   useEffect(() => {
  //  const authToken = localStorage.getItem("accessToken") as string;
  //  getOEMModelData(authToken).then((res) => {
  //    if (res.success) {
  //      const { oem_models, oems } = res.data;

  //      const new_feilds = { ...stepFieldMap };
  //      const oem_option = stepFieldMap["fields"].findIndex(
  //        (field: Field) => field.key === "oem"
  //      );
  //      const oem_model_option = stepFieldMap["fields"].findIndex(
  //        (field: Field) => field.key === "oem_model"
  //      );

  //      new_feilds.fields[oem_option]["option"] = oems;
  //      new_feilds.fields[oem_model_option]["option"] = formData[step]["oem"]
  //        ? oem_models?.filter((obj: any) => obj?.oem === formData[step]["oem"])
  //        : oem_models;
  //      setStepFieldMap(new_feilds);
  //    }
  //  });
  //   }, [formData[step]["oem"]]);
  useEffect(() => {
    const currentFormData = { ...formData[step] };
    const ObjKey = Object.keys(currentFormData);
    let constCount = 0;
    for (let i = 0; i < ObjKey.length; i++) {
      if (currentFormData[ObjKey[i]]) {
        constCount++;
      }
    }
    setCurrentActive(constCount);
  }, [formData[step]]);

  const handleSubmit = () => {
    setLoading(true);
    handleCreateModule(formData[step], userData.id)?.then((res: any) => {
      console.log("%c Line:162 🍑 res", "color:#4fff4B", res);
      if (!res.success) {
        setParamError(errorHandle(res.message));
        setErOpen(true);
        setLoading(false);
      } else {
        const currentDataObj = { ...formData[step] };
        const objectKeys = Object.keys(currentDataObj);
        for (let i = 0; i < objectKeys.length; i++) {
          currentDataObj[objectKeys[i]] = "";
        }
        dispatch(resetStepData({ step: step, value: currentDataObj }));
        dispatch(resetToggle(!formData.toggle));
        setModuleData(res.data);
        setIsTable(true);
        setLoading(false);
      }
    });
  };

  const errorHandle = (errors: any) => {
    const new_err = [];
    // for (const error in errors) {
    //   const stepField = fieldmap[step].fields;
    //   const stepFieldIndex = stepField.findIndex(
    //     (field: any) => field.key == error
    //   );

    //   if (stepFieldIndex >= 0) {
    //     new_err.push(`${stepField[stepFieldIndex].name} : ${errors[error]}`);
    //   }
    // }
    new_err.push(errors?.message);
    return new_err;
  };

  const handleErrorClose = useCallback(() => {
    setErOpen(false);
    setParamError([]);
  }, [paramError, setParamError]);

  const exportToExcelOne = async () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("en-GB");
    const fileName = `daily_prep-${formattedDate}.xlsx`;

    const worksheet = XLSX.utils.aoa_to_sheet([["Serial Number"]]);
    const data = moduleData?.data;
    let currentRow = 2;
    for (let i = 0; i < data?.length; i++) {
      const { identifier } = data[i];
      const rowsToMerge = 1;

      XLSX.utils.sheet_add_aoa(worksheet, [[identifier]], {
        origin: "A" + currentRow,
      });
      worksheet["A" + currentRow].l = { Target: identifier };
      mergeCells(worksheet, currentRow, 1, currentRow + rowsToMerge - 1, 1);
      // currentRow += 1;
      currentRow += rowsToMerge;
    }

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const file = new Blob([excelBuffer], { type: fileType });
    saveAs(file, fileName);
  };
  const mergeCells = (
    worksheet: Worksheet,
    startRow: number,
    startCol: number,
    endRow: number,
    endCol: number
  ): void => {
    const mergeCell: Range = {
      s: { r: startRow - 1, c: startCol - 1 },
      e: { r: endRow - 1, c: endCol - 1 },
    };
    if (!worksheet["!merges"]) worksheet["!merges"] = [];
    worksheet["!merges"].push(mergeCell);
  };
  return (
    <React.Fragment>
      <div className="sm-stepForm-inner">
        <div className="sm-stepForm-banner">
          <SopHeader title={stepFieldMap?.title} link={stepSop[step]} />
        </div>
        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box">
              {stepFieldMap?.fields.map((field: Field, index: number) => {
                if (field.type === "scan") {
                  return (
                    <ScanField
                      status={index <= currentActive}
                      data={field}
                      step={step}
                      value={formData[step][field.key]}
                      key={field.key}
                    />
                  );
                } else if (field.type === "field") {
                  return (
                    <InputField
                      status={index <= currentActive}
                      data={field}
                      step={step}
                      value={
                        formData[step][field.key]
                          ? formData[step][field.key]
                          : ""
                      }
                      key={field.key}
                    />
                  );
                } else if (field.type === "select") {
                  return (
                    <SelectField
                      status={index <= currentActive}
                      data={field}
                      step={step}
                      value={
                        formData[step][field.key]
                          ? formData[step][field.key]
                          : ""
                      }
                      key={field.key}
                    />
                  );
                } else if (field.type === "file") {
                  return (
                    <FileField
                      status={index <= currentActive}
                      data={field}
                      step={step}
                      value={formData[step][field.key]}
                      key={field.key}
                    />
                  );
                } else if (field.type === "file_read") {
                  return (
                    <FileReadField
                      status={index <= currentActive}
                      data={field}
                      step={step}
                      value={formData[step][field.key]}
                      key={field.key}
                    />
                  );
                } else if (field.type === "status") {
                  return (
                    <StatusField
                      status={index <= currentActive}
                      data={field}
                      loading={loading}
                      step={step}
                      value={formData[step][field.key]}
                      key={field.key}
                      onClick={handleSubmit}
                    />
                  );
                } else if (field.type === "custom") {
                  return (
                    <SerialNumberGeneration
                      status={index <= currentActive}
                      data={field}
                      loading={loading}
                      key={field.key}
                      onClick={handleSubmit}
                    />
                  );
                } else if (field.type === "reset") {
                  return (
                    <NavStep
                      step={step}
                      data={field}
                      status={index <= currentActive}
                      key={field.key}
                      onClick={handleSubmit}
                    />
                  );
                }

                return null;
              })}

              {isTable && moduleData && moduleData?.data?.length > 0 && (
                <div className="sm-table-wrapper dailyPrep-table">
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Serial Number</TableCell>
                          {/* <TableCell align="center">Lot Id</TableCell> */}
                          <TableCell align="center">OEM Name</TableCell>
                          <TableCell align="center">Model</TableCell>
                          <TableCell align="right">
                            {" "}
                            <div>
                              <div>
                                <p style={{ marginTop: "24px" }}>Printlist</p>{" "}
                                <ArrowCircleDownIcon
                                  onClick={exportToExcelOne}
                                  sx={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                          </TableCell>

                          {/* <TableCell
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              minHeight: 82,
                            }}
                          >
                            Model
                            <div style={{ display: "flex", gap: "15px" }}>
                              <div>
                                <p>Printlist</p>{" "}
                                <ArrowCircleDownIcon
                                  onClick={exportToExcelOne}
                                  sx={{ cursor: "pointer" }}
                                />
                              </div>
                            </div>
                         
                          </TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {moduleData?.data?.map((module: any) => (
                          <TableRow
                            key={module?.identifier}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {module?.identifier}
                            </TableCell>
                            {/* <TableCell align="right">{module?.lotIdIdentifier}</TableCell> */}
                            <TableCell align="center">{module?.oem}</TableCell>
                            <TableCell align="right">
                              {module?.oem_model}
                            </TableCell>
                            <TableCell align="right"></TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ValidationModel
        open={eropen}
        setOpen={handleErrorClose}
        validation={paramError}
      />
    </React.Fragment>
  );
}
