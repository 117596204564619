import { CloseCircleOutlined, CloudUploadOutlined } from "@ant-design/icons";
import { Button, FormControl, InputLabel, Tooltip } from "@mui/material";
import React, { memo, useEffect, useRef, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useDropzone } from 'react-dropzone';
import { handleFieldData } from "../../../reducers/inspectionReducer";
import { useAppSelector } from "../../../store/rtctype";
import { Field } from "../types";
import Webcame from "./Webcam";
import { CameraAltOutlined } from "@mui/icons-material";

const FileField = ({
  data,
  step,
  value,
  status,
  loading,
}: {
  data: Field;
  step: string;
  value: any;
  status: boolean;
  loading?: boolean;
}) => {
  const dispatch = useDispatch();
  const fileRef = React.useRef<any>(null);
  const [fieldValue, setFieldValue] = React.useState(value);
  const [open, setOpen] = React.useState(false);
  const reset = useAppSelector((state: any) => state.inspection).toggle;
  const { focus } = useAppSelector((state: any) => state.inspection);
  const nextButtonRef = useRef<HTMLButtonElement>(null);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles?.length > 0) {
      const file = acceptedFiles[0];
      if (file?.type?.startsWith("image/")) {
        setFieldValue(file);
      }
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.gif']
    },
    multiple: false
  });

  React.useEffect(() => {
    if (!value) {
      setFieldValue("");
    }
  }, [reset, loading]);

  const handleSave = () => {
    dispatch(
      handleFieldData({
        step: step,
        value: fieldValue,
        key: data.key,
      })
    );
  };

  const handleUpload = () => {
    if (fileRef) {
      fileRef.current.click();
    }
  };

  useEffect(() => {
    if (fieldValue) {
      handleSave();
    }
  }, [fieldValue]);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = event.target.files;
    if (file?.length > 0) {
      if (file[0]?.type.startsWith("image/")) {
        setFieldValue(file[0]);
      } else {
        return;
      }
    }
  };

  const handleRemoveFile = () => {
    if (fileRef?.current) {
      fileRef.current.value = null;
    }
    setFieldValue(null);
    dispatch(
      handleFieldData({
        step: step,
        value: null,
        key: data.key,
      })
    );
  };

  useEffect(() => {
    if (nextButtonRef.current) {
      setTimeout(() => {
        nextButtonRef.current?.focus();
      }, 50);
    }
  }, [focus]);

  return (
    <FormControl variant="standard" className="sm-form-control">
      <InputLabel htmlFor="component-simple">
        {data.name}
        {data.description && (
          <Tooltip className="sm-tooltip" title={data.description} arrow>
            <Button>i</Button>
          </Tooltip>
        )}
      </InputLabel>

      {fieldValue ? (
        <div style={{ 
          border: '1px solid #e0e0e0', 
          borderRadius: '8px', 
          padding: '16px',
          position: 'relative' 
        }}>
          <div style={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            marginBottom: '12px'
          }}>
            <p style={{ margin: 0, fontSize: '14px', color: '#666' }}>
              {fieldValue.name}
            </p>
            <CloseCircleOutlined 
              onClick={handleRemoveFile}
              style={{ 
                cursor: 'pointer',
                color: '#ff4d4f',
                fontSize: '20px'
              }} 
            />
          </div>
          {fieldValue instanceof File && (
            <img 
              src={URL.createObjectURL(fieldValue)} 
              alt="Preview" 
              style={{ 
                width: '100%',
                maxHeight: '300px',
                objectFit: 'contain',
                borderRadius: '4px'
              }} 
            />
          )}
        </div>
      ) : (
        <div
          {...getRootProps()}
          style={{
            border: isDragActive ? '2px dashed #1976d2' : '2px dashed #bdbdbd',
            borderRadius: '8px',
            padding: '24px',
            textAlign: 'center',
            cursor: 'pointer',
            marginTop: '16px',
            backgroundColor: isDragActive ? '#f0f7ff' : '#fafafa',
            transition: 'all 0.3s ease'
          }}
        >
          <input {...getInputProps()} />
          <div style={{ marginBottom: '16px' }}>
            {isDragActive ? (
              <p style={{ color: '#1976d2', fontWeight: 500 }}>Drop the image here ...</p>
            ) : (
              <p style={{ color: '#666' }}>Drag & drop an image here, or click to select</p>
            )}
          </div>
          <div style={{ 
            display: 'flex', 
            gap: '16px', 
            justifyContent: 'center' 
          }}>
            <Button 
              variant="contained" 
              onClick={(e) => {
                e.stopPropagation();
                handleUpload();
              }} 
              disabled={!status}
              style={{ 
                textTransform: 'none',
                minWidth: '120px'
              }}
            >
              <CloudUploadOutlined style={{ marginRight: '8px' }}/>
              Upload
            </Button>
            <Button
              ref={nextButtonRef}
              variant="contained"
              onClick={(e) => {
                e.stopPropagation();
                setOpen(true);
              }}
              disabled={!status}
              style={{ 
                textTransform: 'none',
                minWidth: '120px'
              }}
            >
              <CameraAltOutlined style={{ marginRight: '8px' }} />
              Take Photo
            </Button>
          </div>
        </div>
      )}

      {!fieldValue ? (
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={fieldValue ? false : true}
          style={{ 
            marginTop: '16px',
            width: '100%',
            textTransform: 'none'
          }}
        >
          Save
        </Button>
      ) : (
        <Button
          variant="outlined"
          color="error"
          onClick={handleRemoveFile}
          disabled={fieldValue ? false : true}
          style={{ 
            marginTop: '16px',
            width: '100%',
            textTransform: 'none'
          }}
        >
          Retry
        </Button>
      )}
      
      <Webcame
        open={open}
        setOpen={setOpen}
        setFieldValue={setFieldValue}
        fieldValue={fieldValue}
      />
    </FormControl>
  );
};

export default memo(FileField);
