import React, { useState } from "react";
import SopHeader from "./StepForms/sopHeader";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  CircularProgress,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getCustomerPackId, getPassport } from "../../service/apiService";
import ReportPDF_V2_Customer from "./ReportPDF_V2 customer portal";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import moment from "moment";

const CustomerPortal = () => {
  const customerData = useSelector((state: any) => state.user);
  const [startDate1, setStartDate1] = useState<Date | null>(null);
  const [endDate1, setEndDate1] = useState<Date | null>(null);
  const [qisId, setQisId] = useState("");
  const [data, setData] = useState("");
  const [search, setSearch] = useState("");
  const [packData, setPackData] = useState([]);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [customer, setCustomer] = React.useState<any>("ECAM");
  const staticOptions = [
    { value: "ECAM", label: "ECAM" },
    { value: "Wanco", label: "Wanco" },
    { value: "Ver-Mac", label: "Ver-Mac" },
  ];
  const user = useSelector((state: any) => state.user);

  const handlePassportData = () => {
    setLoading(true);
    getPassport(qisId)
      .then((res: any) => {
        if (res?.success) {
          setData(res?.data?.data);
        }
      })
      .catch((e) => console.log("e=>", e))
      .finally(() => setLoading(false));
  };

  const getData = (data: any) => {
    const params: {
      customer?: string;
      startDate?: any;
      endDate?: any;
      search?: any;
    } = {};

    if (
      customerData?.customer?.role?.role === "Customer Portal-1" &&
      customerData?.customer?.cpType
    ) {
      // params.customer = userId?.includes("ecam.com")
      //   ? "ECAM"
      //   : userId?.includes("wanco.com")
      //   ? "Wanco"
      //   : customer;
      params.customer = customerData?.customer?.cpType;
    } else if (customer) {
      params.customer = customer;
    }
    if (search) {
      params.search = search;
    }
    if (data === "filter") {
      params.startDate = moment(startDate1).format("DD/MM/YYYY");
      params.endDate = moment(endDate1).format("DD/MM/YYYY");
    }
    setLoader(true);
    getCustomerPackId(params)
      ?.then((res: any) => {
        if (res?.success && res?.data) {
          const arr: any = [];
          res?.data?.data &&
            res?.data?.data?.length > 0 &&
            res?.data?.data?.map((obj: any) => {
              arr.push({ label: obj?.packId, value: obj?.packId });
            });
          setPackData(arr);
        }
      })
      .finally(() => {
        setLoader(false);
      });
  };

  React.useEffect(() => {
    if (qisId) handlePassportData();
  }, [qisId]);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      getData("all");
    }, 1500);

    return () => clearTimeout(timer);
  }, [search]);

  React.useEffect(() => {
    getData("all");
  }, [customer]);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <React.Fragment>
      <div className="sm-stepForm-inner" id="kpi">
        <div className="sm-stepForm-banner">
          <SopHeader title={"Customer Portal"} link={""} />
        </div>

        <div className="sm-stepForm-wrap">
          <div className="sm-colm-12">
            <div className="sm-commen-box common common-kpi">
              <div className="sm-commen-box">
                <div className="graph_heading">
                  <input
                    value={search}
                    onChange={(e) => {
                      setSearch(e?.target?.value);
                    }}
                    placeholder="Search by Pack Id"
                    style={{
                      height: "41px",
                      width: "150px",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                      padding: "0 10px",
                    }}
                  />{" "}
                  <div className="graph_filter">
                    <DatePicker
                      selected={startDate1}
                      showIcon
                      toggleCalendarOnIconClick
                      isClearable
                      onChange={(date: Date | null) => setStartDate1(date)}
                      placeholderText="Start date"
                      className="startDate"
                    />
                    <DatePicker
                      selected={endDate1}
                      showIcon
                      toggleCalendarOnIconClick
                      isClearable
                      onChange={(date: Date | null) => setEndDate1(date)}
                      placeholderText="End date"
                      className="startDate"
                    />
                  </div>
                  {customerData?.customer?.role?.role !== "Customer Portal-1" &&
                    !customerData?.customer?.cpType && (
                      <Select
                        value={customer}
                        onChange={(e) => {
                          setPackData([]);
                          setCustomer(e.target.value);
                        }}
                        className={`react-select`}
                        style={{ margin: "5px" }}
                        // displayEmpty
                      >
                        {staticOptions?.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  <Button
                    style={{
                      minHeight: "35px",
                      lineHeight: "22px",
                      maxWidth: "110px",
                      marginLeft: "0",
                    }}
                    onClick={() => getData("filter")}
                    disabled={loading}
                  >
                    Search
                  </Button>
                </div>

                {/* <Select
                        value={modelChart}
                        options={model}
                        className={` react-select `}
                        classNamePrefix="OEM"
                        placeholder="Model"
                        isClearable
                        onChange={(e: any) => {
                          setModelChart(e);
                        }}
                      /> */}
              </div>

              {loader ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : packData && packData?.length > 0 ? (
                packData?.map((option: any) => {
                  return (
                    <Accordion
                      key={option.value}
                      expanded={expanded === option.value}
                      sx={{ border: "1px solid #3498d2", borderRadius: "8px" }}
                      onClick={() => {
                        setData("");
                        setQisId(option.value);
                        handleAccordionChange(option.value);
                      }}
                      onChange={handleAccordionChange(option.value)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${option.value}-content`}
                        sx={{
                          border: "1px solid #3498d2",
                          fontWeight: "700",
                          color: "#111",
                          fontSize: "20px",
                          borderRadius: "8px",
                        }}
                        id={`${option.value}-header`}
                      >
                        {option.value}
                      </AccordionSummary>
                      <AccordionDetails>
                        {expanded === option.value &&
                          (loading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                margin: "20px",
                              }}
                            >
                              <CircularProgress />
                            </div>
                          ) : (
                            <ReportPDF_V2_Customer data={data} />
                          ))}
                      </AccordionDetails>
                    </Accordion>
                  );
                })
              ) : (
                <Accordion
                  sx={{ border: "1px solid #3498d2", borderRadius: "8px" }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      border: "1px solid #3498d2",
                      fontWeight: "600",
                      color: "#111",
                      fontSize: "20px",
                      borderRadius: "8px",
                    }}
                  >
                    No any pack id found
                  </AccordionSummary>
                </Accordion>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerPortal;
