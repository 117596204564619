import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface dataInitState {
  [key: string]: any;
}

const initialState: dataInitState = {
  user: null,
  permission: null,
  loading: false,
  imgLoading: false,
  customer: null,
};

export const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    handleSetUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    handlePermissionUser: (state, action: PayloadAction<any>) => {
      state.permission = action.payload;
    },
    handleCustomer: (state, action: PayloadAction<any>) => {
      state.customer = action.payload;
    },
    handleLoading: (state, action: PayloadAction<any>) => {
      state.loading = action.payload;
    },
    handleImageLoading: (state, action: PayloadAction<any>) => {
      state.imgLoading = action.payload;
    },
  },
});

export const {
  handleSetUser,
  handlePermissionUser,
  handleCustomer,
  handleLoading,
  handleImageLoading,
} = userReducer.actions;
export default userReducer.reducer;
