import React, { useEffect, useState } from "react";
// import imag from "../../../../../assets/images/HIS1_1.jpg"
// import pallet_3 from "../../../../../assets/images/pallet_3.png"
// import passport1 from "../../../../../assets/images/passport1.jpg"
import passport2 from "../../../../../assets/images/passport2.jpg";
import new11 from "../../../../../assets/images/passport_1.jpg";
import nissan1 from "../../../../../assets/images/Topp.jpg";
import nissan2 from "../../../../../assets/images/Right.jpg";
import nissan3 from "../../../../../assets/images/Left.jpg";
// import nissan1 from "../../../../../assets/images/Pack 1.jpg";
// import nissan2 from "../../../../../assets/images/Pack 2.jpg";
// import nissan3 from "../../../../../assets/images/Pack 3.jpg";
import new21 from "../../../../../assets/images/passport_2.jpg";
import new31 from "../../../../../assets/images/passport_3.jpg";
import new1 from "../../../../../assets/images/new1.jpeg";
import new2 from "../../../../../assets/images/new2.jpeg";
import new3 from "../../../../../assets/images/new3.jpeg";
import new4 from "../../../../../assets/images/new4.jpeg";
import intake from "../../../../../assets/images/intake.jpg";
// import n_battery from "../../../../../assets/images/n-battery.jpg"
import Footer from "./Footer";
import HeaderTop from "./Header";
import { useParams } from "react-router-dom";
import { getPassport } from "../../../../../service/apiService";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import MenuSimple from "./FooterMobile";
import styled from "@emotion/styled";
import { set } from "date-fns/esm";

const Header = styled(Typography)({
  backgroundColor: "#3498db",
  color: "white",
  padding: "10px",
  textAlign: "center",
  fontSize: "1.5rem",
  fontWeight: "bold",
});

const Container = styled(Card)({
  minWidth: "80vw",
  maxWidth: "80vw",
  margin: "0 auto",
  border: "1px solid #3498db",
});

const FieldLabel = styled(Typography)({
  margin: "10px 0",
  fontSize: "1rem",
  fontWeight: "bold",
});

const FieldValue = styled(Typography)({
  margin: "10px 0",
  fontSize: "1rem",
  fontWeight: "normal",
});

const ReportPDF_V2 = () => {
  const { passport } = useParams();
  const [tab, setTab] = useState("intake");
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState<any>([]);
  const [lotId, setLotId] = useState<any>([]);
  const [imgOne, setImgOne] = useState<any>(nissan1);
  const [imgTwo, setImgTwo] = useState<any>(nissan2);
  const [imgThree, setImgThree] = useState<any>(nissan3);
  const [insImg, setInsImg] = useState<any>(intake);

  const arrWithoutDuplicates: string[] = lotId?.filter(
    (item: any, index: any) => lotId?.indexOf(item) === index
  );

  const handlePassportData = () => {
    setLoader(true);
    getPassport(passport)
      .then((res) => {
        if (res?.success) {
          setData(res?.data?.data);
          setImgOne(res?.data?.data?.qis?.photoStationTop || nissan1);
          setImgTwo(res?.data?.data?.qis?.photoStationRight || nissan2);
          setImgThree(res?.data?.data?.qis?.photoStationLeft || nissan3);
          setInsImg(
            res?.data?.data?.moduleids?.length > 0
              ? res?.data?.data?.moduleids?.find(
                  (obj: any) => obj?.images?.length > 0
                )?.images[0] || intake
              : intake
          );
          setLotId(
            res?.data?.data?.moduleids?.map(
              (obj: any) => obj?.lotId ?? `${obj?.lotId}, `
            )
          );
        }
      })
      .catch((e) => console.log("e=>", e))
      .finally(() => setLoader(false));
  };
  React.useEffect(() => {
    handlePassportData();
  }, [passport]);

  const Image = () => {
    return (
      <div
        className="flex-row jusify-center passport-images"
        style={{ gap: "30px" }}
      >
        {data?.oem_code === "ABO" ? (
          <>
            <img
              src={new11}
              alt=""
              className="passport-img"
              style={{ width: "300px", height: "220px" }}
            />
            <img
              src={new21}
              alt=""
              className="passport-img"
              style={{ width: "300px", height: "220px" }}
            />
            <img
              src={new31}
              alt=""
              className="passport-img"
              style={{ width: "300px", height: "220px" }}
            />
          </>
        ) : (
          <>
            <img
              src={imgOne || nissan1}
              alt=""
              className="passport-img"
              style={{ width: "300px", height: "220px" }}
              onError={() => {
                setImgOne(nissan1);
              }}
            />
            <img
              src={imgTwo || nissan2}
              alt=""
              className="passport-img"
              style={{ width: "300px", height: "220px" }}
              onError={() => {
                setImgTwo(nissan2);
              }}
            />
            <img
              src={imgThree || nissan3}
              alt=""
              className="passport-img"
              style={{ width: "300px", height: "220px" }}
              onError={() => {
                setImgThree(nissan3);
              }}
            />
            {/* <img
              src={new4}
              alt=""
              className="passport-img"
              style={{ width: "220px", height: "220px" }}
            /> */}
          </>
        )}
      </div>
    );
  };
  const TableTile = () => {
    return (
      <div
        className="flex-row jusify-center passport-table"
        // style={{ gap: "60px" }}
      >
        {/* <div className="flex-col  w-50"> */}
        <div className="passport-table-subdiv">
          <Table size="small" aria-label="a dense table">
            <TableHead style={{ backgroundColor: "#3498d2" }}>
              <TableRow>
                <TableCell
                  colSpan={3}
                  style={{
                    color: "white",
                    border: "2px solid #3498d2",
                    fontSize: "15px",
                  }}
                >
                  Health Indicator
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  component="th"
                  scope="row"
                >
                  Parameter
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  align="right"
                >
                  Value
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  align="right"
                >
                  Units
                </TableCell>
              </TableRow>{" "}
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="row"
                >
                  Make & Model
                </TableCell>

                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  {data?.oem_code === "ABO"
                    ? "Caterpillar"
                    : `Nissan ${
                        data?.oem_model === "AAD"
                          ? "Gen 4"
                          : data?.oem_model === "AAB"
                          ? "Gen 2"
                          : "Gen 3"
                      }`}
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  -
                </TableCell>
              </TableRow>{" "}
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="row"
                >
                  Chemistry
                </TableCell>

                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  Li-ion
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  -
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className="passport-table-subdiv">
          <Table size="small" aria-label="a dense table">
            <TableHead style={{ backgroundColor: "#3498d2" }}>
              <TableRow>
                <TableCell
                  colSpan={3}
                  style={{
                    color: "white",
                    border: "2px solid #3498d2",
                    fontSize: "15px",
                  }}
                >
                  Health Indicator
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  component="th"
                  scope="row"
                >
                  Parameter
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  align="right"
                >
                  Value
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  align="right"
                >
                  Units
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="row"
                >
                  Max Voltage
                </TableCell>

                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  {data?.oem_code === "ABO" ? "58.8" : "33.6"}
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  V
                </TableCell>
              </TableRow>{" "}
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="row"
                >
                  Min Voltage
                </TableCell>

                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  {data?.oem_code === "ABO" ? "42" : "20"}
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  V
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
        <div className="passport-table-subdiv">
          <Table size="small" aria-label="a dense table">
            <TableHead style={{ backgroundColor: "#3498d2" }}>
              <TableRow>
                <TableCell
                  colSpan={3}
                  style={{
                    color: "white",
                    border: "2px solid #3498d2",
                    fontSize: "15px",
                  }}
                >
                  Health Indicator
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  component="th"
                  scope="row"
                >
                  Parameter
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  align="right"
                >
                  Value
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontWeight: "600",
                  }}
                  align="right"
                >
                  Units
                </TableCell>
              </TableRow>
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="row"
                >
                  Nominal Voltage
                </TableCell>

                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  {data?.oem_code === "ABO" ? "50" : "28.8"}
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  V
                </TableCell>
              </TableRow>{" "}
              <TableRow
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  component="th"
                  scope="row"
                >
                  Rated Capacity
                </TableCell>

                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  {data?.oem_code === "ABO"
                    ? "315"
                    : data?.oem_code === "AAK"
                    ? "232"
                    : data?.oem_code === "AAU" && data?.oem_model === "AAD"
                    ? "112"
                    : data?.oem_code === "AAU" && data?.oem_model === "AAB"
                    ? "66"
                    : "84"}
                </TableCell>
                <TableCell
                  style={{
                    border: "2px solid #3498d2",
                    fontSize: "12px",
                  }}
                  align="right"
                >
                  Ah
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    );
  };
  const Intake = () => {
    return (
      <>
        <div style={{ marginBottom: "30px", minHeight: "400px" }}>
          <div className="flex-col">
            {/* <p className="headText">
              NthLife <span>TM</span> Battery Passport
            </p> */}
            <p className="headText packId">Pack Id: {passport}</p>
            <Image />
            <TableTile />
            <div className="mobile_nav">
              <MenuSimple handleChange={setTab} tab={tab} />
            </div>
            <div className="mobile_nav">
              <Container>
                <Header> Module inspection intake</Header>
                <CardContent>
                  <Grid container direction="column">
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Initial Received Date</FieldLabel>
                      <FieldValue>
                        {data?.moduleids?.length > 0
                          ? moment(
                              data?.moduleids?.find((obj: any) => obj?.lotDate)
                                ?.lotDate
                            )?.format("MM/DD/YYYY")
                          : "10/24/2023"}
                      </FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Inspection Site</FieldLabel>
                      <FieldValue>Houston</FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Incoming LOT ID</FieldLabel>
                      {data?.moduleids?.length > 0 ? (
                        <FieldValue>
                          {Array.from(
                            new Set(
                              data?.moduleids?.map((obj: any) =>
                                obj?.lotId ? obj?.lotId : ""
                              )
                            )
                          )
                            ?.filter(Boolean)
                            ?.join(", ")}
                        </FieldValue>
                      ) : (
                        <FieldValue>101823-AAU-AA</FieldValue>
                      )}
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Inspection intake Lot Image</FieldLabel>
                      <FieldValue>
                        <img
                          src={intake}
                          alt=""
                          className="passport-img"
                          style={{
                            width: "100%",
                            height: "200px",
                            marginInline: "auto",
                          }}
                          onError={() => {
                            setInsImg(intake);
                          }}
                        />
                      </FieldValue>
                    </Grid>
                  </Grid>
                </CardContent>
              </Container>
            </div>
            <div
              className="flex-col w-80 gap-4 desktop_nav"
              style={{
                // paddingTop: "15px",
                background: "#3498d2",
                minHeight: "60vh",
                border: "2px solid #3498d2",
              }}
            >
              <Footer
                handleChange={setTab}
                tab={tab}
                className="w-full desktop_nav"
              />
              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "white",
                  marginBottom: "5px",
                }}
              >
                Module inspection intake
              </h3>
              <div
                className="flex-col w-full gap-8"
                style={{
                  minHeight: "60vh",
                  background: "#f7f8fa",
                }}
              >
                <div className="con">
                  <p className="bold">Initial Received Date</p>
                  <p className="bold">
                    {data?.moduleids?.length > 0
                      ? moment(
                          data?.moduleids?.find((obj: any) => obj?.lotDate)
                            ?.lotDate
                        )?.format("MM/DD/YYYY")
                      : "10/24/2023"}
                  </p>
                </div>
                <div className="con">
                  <p className="bold">Inspection Site</p>
                  <p className="bold">Houston</p>
                </div>
                <div className="con">
                  <p className="bold">Incoming LOT ID</p>
                  {data?.moduleids?.length > 0 ? (
                    <p className="bold" style={{ wordBreak: "break-all" }}>
                      {Array.from(
                        new Set(
                          data?.moduleids?.map((obj: any) =>
                            obj?.lotId ? obj?.lotId : ""
                          )
                        )
                      )
                        ?.filter(Boolean)
                        ?.join(", ")}
                    </p>
                  ) : (
                    <p className="bold" style={{ wordBreak: "break-all" }}>
                      101823-AAU-AA
                    </p>
                  )}
                </div>
                <div className="con">
                  <p className="bold">Inspection intake Lot Image</p>
                  <img
                    src={intake}
                    alt=""
                    className="passport-img"
                    style={{
                      width: "100%",
                      height: "200px",
                      marginInline: "auto",
                    }}
                    onError={() => {
                      setInsImg(intake);
                    }}
                  />
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  const Reverse = () => {
    return (
      <>
        {" "}
        <div style={{ marginBottom: "30px" }}>
          <div className="flex-col">
            {/* <p className="headText">
              NthLife <span>TM</span> Battery Passport
            </p> */}
            <p className="headText packId">Pack Id: {passport}</p>
            <Image />
            <TableTile />
            <div className="mobile_nav">
              <MenuSimple handleChange={setTab} tab={tab} />
            </div>
            <div className="mobile_nav">
              <Container>
                <Header> Battery Pack Reverse Logistics Intake</Header>
                <CardContent>
                  <Grid container direction="column">
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Initial Received Date</FieldLabel>
                      <FieldValue></FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Inspection Site</FieldLabel>
                      <FieldValue></FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Inspection intake Lot #</FieldLabel>
                      <FieldValue></FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Inspection intake Lot Image</FieldLabel>
                      <FieldValue></FieldValue>
                    </Grid>
                  </Grid>
                </CardContent>
              </Container>
            </div>
            <div
              className="flex-col w-80 gap-4 desktop_nav"
              style={{
                // paddingTop: "15px",
                background: "#3498d2",
                minHeight: "60vh",
                border: "2px solid #3498d2",
              }}
            >
              <Footer
                handleChange={setTab}
                tab={tab}
                className="w-full desktop_nav"
              />
              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "white",
                  marginBottom: "5px",
                }}
              >
                Battery Pack Reverse Logistics Intake
              </h3>
              <div
                className="flex-col w-full gap-4"
                style={{
                  minHeight: "60vh",
                  background: "#f7f8fa",
                }}
              >
                <div className="con">
                  <p className="bold">Initial Received Date</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Inspection Site</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Inspection intake Lot #</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Inspection intake Lot Image</p>
                  {/* <img
                  src={imag}
                  alt=""
                  className="passport-img"
                  style={{width: "300px", height: "300px"}}
                /> */}
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  const Inspection = () => {
    return (
      <>
        <div style={{ marginBottom: "30px" }}>
          <div className="flex-col">
            {/* <p className="headText">
              NthLife <span>TM</span> Battery Passport
            </p> */}
            <p className="headText packId">Pack Id: {passport}</p>
            <Image />
            <TableTile />
            <div className="mobile_nav">
              <MenuSimple handleChange={setTab} tab={tab} />
            </div>
            <div className="mobile_nav">
              <Container>
                <Header>Battery Inspection Report</Header>
                <CardContent>
                  {/* <Box style={{ height: "fit-content" }}> */}
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Module Id</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell>Link</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data &&
                          data?.moduleids &&
                          data?.moduleids?.length > 0 &&
                          data?.moduleids?.map((obj: any) => {
                            return (
                              <TableRow>
                                <TableCell>{obj?.battery_module}</TableCell>
                                <TableCell>
                                  {" "}
                                  {data &&
                                    moment(data?.timeOfHis6).format(
                                      "MM/DD/YYYY"
                                    )}
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <p
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      window.open(`${obj?.reportUrl}`, "_blank")
                                    }
                                  >
                                    {`${obj?.reportUrl || ""}`}
                                  </p>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {/* </Box> */}
                </CardContent>
              </Container>
            </div>
            <div
              className="flex-col w-80 gap-4 desktop_nav"
              style={{
                // paddingTop: "15px",
                background: "#3498d2",
                // minHeight: "60vh",
                border: "2px solid #3498d2",
              }}
            >
              <Footer
                handleChange={setTab}
                tab={tab}
                className="w-full desktop_nav"
              />
              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "white",
                  marginBottom: "5px",
                }}
              >
                Battery Inspection Report
              </h3>
              <div
                className="flex-col w-full gap-8 "
                style={{
                  paddingBlock: "15px",
                  // background: "black",
                  minHeight: "60vh",
                  maxWidth: "full",
                  background: "#f7f8fa",
                }}
              >
                <Table size="small" aria-label="a dense table">
                  <TableBody>
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        style={{
                          border: "2px solid #3498d2",
                          fontWeight: "600",
                          color: "black",
                        }}
                        component="th"
                        scope="row"
                      >
                        Module Id
                      </TableCell>
                      <TableCell
                        style={{
                          border: "2px solid #3498d2",
                          fontWeight: "600",
                          color: "black",
                        }}
                        align="right"
                      >
                        Date
                      </TableCell>
                      <TableCell
                        style={{
                          border: "2px solid #3498d2",
                          fontWeight: "600",
                          color: "black",
                        }}
                        align="right"
                      >
                        Link
                      </TableCell>
                    </TableRow>{" "}
                    {data &&
                      data?.moduleids &&
                      data?.moduleids?.length > 0 &&
                      data?.moduleids?.map((obj: any) => {
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                color: "black",
                              }}
                              component="th"
                              scope="row"
                            >
                              {obj?.battery_module}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                color: "black",
                              }}
                              align="right"
                            >
                              {data &&
                                moment(data?.timeOfHis6).format("MM/DD/YYYY")}
                            </TableCell>
                            <TableCell
                              style={{
                                border: "2px solid #3498d2",
                                color: "black",
                              }}
                              align="right"
                            >
                              <p
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  window.open(`${obj?.reportUrl}`, "_blank")
                                }
                              >
                                {" "}
                                {`${obj?.reportUrl || ""}`}
                              </p>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const Assembly = () => {
    return (
      <>
        <div style={{ marginBottom: "30px" }}>
          <div className="flex-col">
            {/* <p className="headText">
              NthLife <span>TM</span> Battery Passport
            </p> */}
            <p className="headText packId">Pack Id: {passport}</p>
            <Image />
            <TableTile />
            <div className="mobile_nav">
              <MenuSimple handleChange={setTab} tab={tab} />
            </div>
            <div className="mobile_nav">
              <Container>
                <Header>Battery Pack Assembly</Header>
                <CardContent>
                  <Grid container direction="column">
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Battery Pack Assembly Date</FieldLabel>
                      <FieldValue>
                        {data?.qis && data?.qis?.packPackagingDate
                          ? moment(data?.qis?.packPackagingDate)?.format(
                              "MM/DD/YYYY"
                            )
                          : "In-Progress"}
                      </FieldValue>
                      {/* <FieldValue>01/14/2024</FieldValue> */}
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Battery Pack Assembly Report Link</FieldLabel>
                      {data?.qis && data?.qis?.completedQis === 5 ? (
                        <FieldValue
                          sx={{ cursor: "pointer" }}
                          onClick={() =>
                            window.open(
                              `${window.location.origin}/#/qis-report-download/${passport}`,
                              "_blank"
                            )
                          }
                        >{`passport${window.location.origin}/#/qis-report-download/${passport}`}</FieldValue>
                      ) : (
                        <FieldValue>In-Progress</FieldValue>
                      )}
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Inspection intake Lot Image</FieldLabel>
                      {data?.qis && data?.qis?.completedQis === 5 ? (
                        <FieldValue>
                          <img
                            src={
                              data?.qis?.photoStationTop
                                ? data?.qis?.photoStationTop
                                : data?.oem_code === "ABO"
                                ? new11
                                : passport2
                            }
                            alt=""
                            className="passport-img"
                            style={{
                              width: "250px",
                              height: "200px",
                              marginInline: "auto",
                            }}
                          />
                        </FieldValue>
                      ) : (
                        <FieldValue>In-Progress</FieldValue>
                      )}
                    </Grid>
                  </Grid>
                </CardContent>
              </Container>
            </div>
            <div
              className="flex-col w-80 gap-4 desktop_nav"
              style={{
                // paddingTop: "15px",
                background: "#3498d2",
                border: "2px solid #3498d2",
                // minHeight: "60vh",
              }}
            >
              <Footer
                handleChange={setTab}
                tab={tab}
                className="w-full desktop_nav"
              />

              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "white",
                  marginBottom: "5px",
                }}
              >
                Battery Pack Assembly
              </h3>
              <div
                className="flex-col w-full gap-8"
                style={{
                  minHeight: "60vh",
                  paddingBlock: "15px",
                  // background: "black",
                  maxWidth: "full",
                  background: "#f7f8fa",
                }}
              >
                {/* <div className="con">
                  <p className="bold">Battery Pack QR Code</p>
                  <p className="bold">12cds21561 </p>
                </div> */}
                <div className="con">
                  <p className="bold">Battery Pack Assembly Date</p>
                  <p className="bold">
                    {" "}
                    {data?.qis && data?.qis?.packPackagingDate
                      ? moment(data?.qis?.packPackagingDate)?.format(
                          "MM/DD/YYYY"
                        )
                      : "In-Progress"}
                  </p>
                </div>
                <div className="con">
                  <p className="bold">Battery Pack Assembly Report Link</p>
                  {data?.qis && data?.qis?.completedQis === 5 ? (
                    <p
                      className="bold"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        window.open(
                          `${window.location.origin}/#/qis-report-download/${passport}`,
                          "_blank"
                        )
                      }
                    >{`${window.location.origin}/#/qis-report-download/${passport}`}</p>
                  ) : (
                    <p className="bold">In-progress</p>
                  )}
                </div>
                <div className="con">
                  <p className="bold">Inspection intake Lot Image</p>
                  {data?.qis && data?.qis?.completedQis === 5 ? (
                    <img
                      src={
                        data?.qis?.photoStationTop
                          ? data?.qis?.photoStationTop
                          : data?.oem_code === "ABO"
                          ? new11
                          : passport2
                      }
                      alt=""
                      className="passport-img"
                      style={{
                        width: "250px",
                        height: "200px",
                        marginInline: "auto",
                      }}
                    />
                  ) : (
                    <p className="bold">In-progress</p>
                  )}
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  const Deployment = () => {
    return (
      <>
        <div style={{ marginBottom: "30px" }}>
          <div className="flex-col">
            {/* <p className="headText">
              NthLife <span>TM</span> Battery Passport
            </p> */}
            <p className="headText packId">Pack Id: {passport}</p>
            <Image />
            <TableTile />
            <div className="mobile_nav">
              <MenuSimple handleChange={setTab} tab={tab} />
            </div>
            <div className="mobile_nav">
              <Container>
                <Header>Battery Pack Field Deployment</Header>
                <CardContent>
                  <Grid container direction="column">
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Field Market Segment</FieldLabel>
                      <FieldValue>{data && data?.fieldSegment}</FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Customer Name</FieldLabel>
                      <FieldValue>{data && data?.customer}</FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Customer ID #</FieldLabel>
                      <FieldValue>
                        {data && data?.columb?.packID
                          ? data?.columb?.packID
                          : "5250"}
                      </FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>
                        Battery Pack Field Deployment Date
                      </FieldLabel>
                      <FieldValue>
                        {data && data?.columb?.onboardingDate
                          ? moment(data?.columb?.onboardingDate).format(
                              "MM/DD/YYYY"
                            )
                          : ""}
                      </FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>
                        Battery Pack Field Use Report Link
                      </FieldLabel>
                      <FieldValue></FieldValue>
                    </Grid>
                  </Grid>
                </CardContent>
              </Container>
            </div>
            <div
              className="flex-col w-80 gap-4 desktop_nav"
              style={{
                // paddingTop: "15px",
                background: "#3498d2",
                // minHeight: "60vh",
                border: "2px solid #3498d2",
              }}
            >
              <Footer
                handleChange={setTab}
                tab={tab}
                className="w-full desktop_nav"
              />
              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "white",
                  marginBottom: "5px",
                }}
              >
                Battery Pack Field Deployment
              </h3>
              <div
                className="flex-col w-full gap-8"
                style={{
                  minHeight: "60vh",
                  background: "#f7f8fa",
                }}
              >
                {/* <div className="con">
                  <p className="bold">Battery Pack QR Code</p>
                  <p className="bold">12cds21561 </p>
                </div> */}
                <div className="con">
                  <p className="bold">Field Market Segment</p>
                  <p className="bold">{data && data?.fieldSegment}</p>
                </div>
                <div className="con">
                  <p className="bold">Customer Name</p>
                  <p className="bold">{data && data?.customer}</p>
                </div>
                <div className="con">
                  <p className="bold">Customer ID #</p>
                  <p className="bold">
                    {data && data?.columb?.packID
                      ? data?.columb?.packID
                      : "5250"}
                  </p>
                </div>
                <div className="con">
                  <p className="bold">Battery Pack Field Deployment Date</p>
                  <p className="bold">
                    {data && data?.columb?.onboardingDate
                      ? moment(data?.columb?.onboardingDate).format(
                          "MM/DD/YYYY"
                        )
                      : ""}
                  </p>
                </div>
                <div className="con">
                  <p className="bold">Battery Pack Field Use Report Link</p>
                  <p className="bold"></p>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  const Recycle = () => {
    return (
      <>
        <div style={{ marginBottom: "30px" }}>
          <div className="flex-col">
            {/* <p className="headText">
              NthLife <span>TM</span> Battery Passport
            </p> */}
            <p className="headText packId">Pack Id: {passport}</p>
            <Image />
            <TableTile />
            <div className="mobile_nav">
              {" "}
              <MenuSimple handleChange={setTab} tab={tab} />
            </div>
            <div className="mobile_nav">
              <Container>
                <Header>Battery Recycling</Header>
                <CardContent>
                  <Grid container direction="column">
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Recycling Date</FieldLabel>
                      <FieldValue></FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Recycling Site</FieldLabel>
                      <FieldValue></FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Recycling Firm Name</FieldLabel>
                      <FieldValue></FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Recycling Firm ID #</FieldLabel>
                      <FieldValue></FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>Recycling Completion Date</FieldLabel>
                      <FieldValue></FieldValue>
                    </Grid>
                    <Grid item style={{ marginBottom: "10px" }}>
                      <FieldLabel>
                        Proof of Recycling Certificate Link
                      </FieldLabel>
                      <FieldValue></FieldValue>
                    </Grid>
                  </Grid>
                </CardContent>
              </Container>
            </div>
            <div
              className="flex-col w-80 gap-4 desktop_nav"
              style={{
                // paddingTop: "15px",
                background: "#3498d2",
                // minHeight: "60vh",
                border: "2px solid #3498d2",
              }}
            >
              <Footer
                handleChange={setTab}
                tab={tab}
                className="w-full desktop_nav"
              />

              <h3
                style={{
                  fontSize: "25px",
                  fontWeight: 500,
                  textAlign: "center",
                  color: "white",
                  marginBottom: "5px",
                }}
              >
                Battery Recycling
              </h3>
              <div
                className="flex-col w-full gap-8 "
                style={{
                  minHeight: "60vh",
                  background: "#f7f8fa",
                }}
              >
                <div className="con">
                  <p className="bold">Recycling Date </p>
                  <p className="bold"> </p>
                </div>
                <div className="con">
                  <p className="bold">Recycling Site</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Recycling Firm Name</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Recycling Firm ID #</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Recycling Completion Date</p>
                  <p className="bold"></p>
                </div>
                <div className="con">
                  <p className="bold">Proof of Recycling Certificate Link</p>
                  <p className="bold"></p>
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
      </>
    );
  };
  return (
    <div
      className="passport"
      style={
        loader
          ? { marginTop: "-100vh", width: "100vw" }
          : { marginTop: "-100vh", width: "80vw " }
      }
    >
      {loader ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
          }}
        >
          <Button
            variant="contained"
            style={{
              backgroundColor: "#212121",
              width: "30%",
              cursor: "default",
            }}
          >
            <span className="report_loader"></span>Generating Passport
          </Button>
        </div>
      ) : (
        <>
          <HeaderTop />
          {tab === "intake" && <Intake />}
          {tab === "deployment" && <Deployment />}
          {tab === "reverse" && <Reverse />}
          {tab === "recycling" && <Recycle />}
          {tab === "inspection" && <Inspection />}
          {tab === "assembly" && <Assembly />}
        </>
      )}
    </div>
  );
};

export default ReportPDF_V2;
